import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx bxs-dashboard',
        link: '/dashboard/dashboard',
    },
    {
        id: 2,
        label: 'Liste des factures',
        icon: 'bx bx-receipt',
        link: '/dashboard/invoice',
    },
    {
        id: 6,
        label: 'Data Table',
        icon: 'bx bx-receipt',
        link: '/dashboard/datateble',
    },
    
    
    {
        id: 3,
        label: 'Filtre',
        icon: 'bx bx-filter-alt',
        link: '/dashboard/filter',
    },
    
    {
        id: 4,
        label: 'Agences',
        icon: 'bx-home-circle',
        subItems: [
            {
                id: 5,
                label: 'Chargement en cour...',
              
                parentId: 4
            },
        ]
    },


];
export const MENUAGENCE: MenuItem[] = [
    {
        id: 1,
        label: 'Liste des patrimoines',
        icon: 'bx-home-circle',
        link: '',
    },
    {
        id: 2,
        label: 'Contacter le support',
        icon: 'bx bx-support',
        link: '/dashboard/support',
    },
    
];

